import React, { useState, useEffect } from 'react'
import MDBox from "components/MDBox";
import DashboardLayout from "tools/LayoutContainers/DashboardLayout";
import DashboardNavbar from "tools/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CorporateCard from './corporateCard';

export default function CorporateMembers() {

    const members = [

        {
            "title": "AB Mauri",
            "description": "AB Mauri is a business devoted to the baking industry, supporting and enabling the world’s bakers, both small and large.",
            "image": "/corporate_logos/ABMauriLogo.svg",
            "website": "https://www.abmauri.com/"
        },

        {
            "title": "ADM",
            "description": "We purchase, transport and transform crops, connecting farmers to consumers, touching millions of lives daily.",
            "image": "/corporate_logos/adm.png",
            "website": "https://www.adm.com/"
        },

        {
            "title": "Amcor",
            "description": "We are a global leader in developing and producing responsible packaging for food, beverage, pharmaceutical, medical, home and personal-care, and other products.",
            "image": "/corporate_logos/amcor.png",
            "website": "https://www.amcor.com/"
        },
        {
            "title": "Amber Wave",
            "description": "Your trusted domestic source for consistent, high-quality wheat protein.",
            "image": "/corporate_logos/amberwave.jpg",
            "website": "https://amberwaveusa.com/"
        },

        {
            "title": "AMF Bakery Systems",
            "description": "Bakeries around the world partner with AMF for improved operations, products, and bakery success, which we deliver through our industry-leading technology brands.",
            "image": "/corporate_logos/amf.jpeg",
            "website": "https://www.amfbakery.com/"
        },

        {
            "title": "Ardent Mills",
            "description": "Transforming how the world is nourished It's ingrained in us",
            "image": "/corporate_logos/ardentMills.svg",
            "website": "https://www.ardentMills.com/"
        },

        {
            "title": "Avant Food Media",
            "description": "Serving up stories that are shaping the commercial baking industry.",
            "image": "/corporate_logos/avant.svg",
            "website": "https://commercialbaking.com/"
        },

        {
            "title": "BakeMark",
            "description": "With over 200 years of service to the baking industry, BakeMark is recognized as an industry leader and trusted partner for quality bakery ingredients, products, supplies and service.",
            "image": "/corporate_logos/bakemark.png",
            "website": "https://bakemark.com/"
        },

        {
            "title": "BC Williams",
            "description": "We are able to consistently meet, and often exceed, the needs of our customers. With over 85 years of proven experience, we are confident that we can be of service, and value to your corporation.",
            "image": "/corporate_logos/bcwilliams.png",
            "website": "https://bcwilliams.com/"
        },
        {
            "title": "Benchmark",
            "description": "Food Distribution and Loading Solutions for Increased Line Efficiency and Superior Performance.",
            "image": "/corporate_logos/benchmark.svg",
            "website": "https://benchmarkautomation.net/"
        },

        {
            "title": "Burford Corp.",
            "description": "Burford is a global leader in innovating automated equipment for high-speed bread and bun lines.",
            "image": "/corporate_logos/burford_middlebyLogo.jpeg",
            "website": "https://burford.com/"
        },

        {
            "title": "Cain Food Industries",
            "description": "Practical Solutions through Innovative Technologies. That’s our promise and that’s what we live by in Maysville, Oklahoma. Our promise to continue to provide you with quality custom products coupled with individual attention, it's what we do.",
            "image": "/corporate_logos/cain.png",
            "website": "https://cainfood.com/"
        },

       

        {
            "title": "Corbion",
            "description": "Corbion is the global market leader in lactic acid and its derivatives, and a leading supplier of emulsifiers, functional enzyme blends, minerals, vitamins, and algae ingredients.",
            "image": "/corporate_logos/corbion.png",
            "website": "https://www.corbion.com/"
        },

        {
            "title": "D. Thomas & Associates",
            "description": "Since 1973, D Thomas + Associates has delivered customer-driven sales, service and support on behalf of a diverse group of best-in-class packaging manufacturers. We believe in the power of strong and dependable relationships and that our team’s collective intelligence adds value by creating innovative solutions for our clients.",
            "image": "/corporate_logos/dthomas.png",
            "website": "https://dthos.com/"
        },

        {
            "title": "Dakota Specialty Mining",
            "description": "DakotaMB is a family-owned business in America’s heartland that has partnered with some of the largest bakeries, snack producers and cereal manufacturers in the world. We’ve merged two strong companies, Dakota Specialty Milling and Dakota Blenders, into DakotaMB to better serve our customers with custom blends and mixes.",
            "image": "/corporate_logos/dakotamb.png",
            "website": "https://dakotamb.com/"
        },

        {
            "title": "Dawn Foods",
            "description": "As a global leader, we continue to transform the industry and inspire our customers’ success by having the best people in the business, industry-leading products, and meaningful partnerships with our customers.",
            "image": "/corporate_logos/dawn.png",
            "website": "https://dawnfoods.com/"
        },

        {
            "title": "Foremost Fuji Corp.",
            "description": "Formost Fuji provides solutions for all types of industries, feel free to search through many of the different applications that we have provided solutions for. If you do not see your specific application, feel free to contact us and we would be happy to review your project individually.",
            "image": "/corporate_logos/foremost.png",
            "website": "https://www.formostfuji.com/"
        },

        {
            "title": "Grain Craft",
            "description": "Grain Craft is the largest independent flour miller in the nation, offering you premium bulk and bagged flours for the baking, food service, tortilla and pizza industries. In total, we can efficiently serve you through 12 milling locations spread coast to coast. But far more important than Grain Craft’s production capacity is our unmatched capacity to serve your needs.",
            "image": "/corporate_logos/graincraft.png",
            "website": "https://www.graincraft.com/"
        },

        {
            "title": "IFF",
            "description": "We offer end-to-end service that few can deliver on. Our unparalleled product portfolio is the most robust in the industry and we have leadership positions across key taste, texture, scent, nutrition, enzymes, cultures, soy proteins and probiotics categories.",
            "image": "/corporate_logos/iff.png",
            "website": "https://www.iff.com/"
        },

        {
            "title": "J & K Ingredients",
            "description": "J&K Ingredients, Inc. is a leading manufacturer of bakery ingredients for the baking industry throughout the world. We have reached this benchmark, and will continue to uphold it.",
            "image": "/corporate_logos/jk.png",
            "website": "https://www.jkingredients.com/"
        },

        {
            "title": "Jennings, Cobble LLC",
            "description": "Merchandize flour and bakery products for the wholesale bakery industry.  Manufacture multi-grain mixes to sell to top baking institutions for multi-grain breads. Purchase, sell, broker in wheat, flour and bakery products of all types as well as agricultural products and by-products.",
            "image": "/corporate_logos/cobble.png",
            "website": "https://www.linkedin.com/company/jenco-llc-jennings-cobble-llc/about/"
        },

        {
            "title": "Kwik Lok",
            "description": "Kwik Lok leads the way in innovating reliable, sustainable packaging, and new product solutions that improve the lives of our employees, customers, and communities.",
            "image": "/corporate_logos/kwik.png",
            "website": "https://www.kwiklok.com/"
        },

        {
            "title": "Lallemand",
            "description": "Lallemand is a global leader in the development, production and marketing of yeast, bacteria and specialty ingredients.",
            "image": "/corporate_logos/lallemand.png",
            "website": "https://www.lallemand.com/"
        },

        {
            "title": "LeMatic",
            "description": "From traditional bakery equipment or a line that incorporates the latest vision and robotic technology, the LeCrone family and LeMatic’s professional staff continue to offer only the very best to the baking industry.",
            "image": "/corporate_logos/lematic.jpg",
            "website": "https://lematic.com/"
        },

        {
            "title": "Lesaffre/Red Start Yeast Co.",
            "description": "Across the span of almost 200 years, we have grown from offering the finest in yeast to serving as one of the largest ingredient suppliers in commercial baking. Rise to the top with Lesaffre.",
            "image": "/corporate_logos/lesaffre.svg",
            "website": "https://lesaffrebaking.com/"
        },

        {
            "title": "Lockton Companies",
            "description": "As a family organization, we are not driven by the quarterly pressure from the financial markets. This kind of independence frees us to always act in the best interest of our clients and creates a completely different dynamic, one that is focused on your success.",
            "image": "/corporate_logos/lockton.jpg",
            "website": "https://global.lockton.com/us/en"
        },

        {
            "title": "Malt Products Corporation",
            "description": "Malt Products is a family business that manufactures and distributes natural, nutritious sweeteners. We have been in business since the 1950s and have grown into an international company offering a full line of natural, nutritious grain extracts and sweeteners.",
            "image": "/corporate_logos/malt.png",
            "website": "https://maltproducts.com/maltproducts/"
        },

        {
            "title": "Manildra Group USA",
            "description": "Next generation ingredients to support your food products. Dedicated experts to support your innovation.",
            "image": "/corporate_logos/manildra.png",
            "website": "https://manildrausa.com/"
        },

        {
            "title": "McGahee, Lacy & Associates",
            "description": "Meeting the needs of our customers while surpassing expectations is priority number one at McGahee, Lacy & Associates. This purposeful business approach for over 40 years and counting, is proven by the innovative solutions we deliver, the combined knowledge of our staff, and the turn-key operational structure that is demonstrated to those we serve in the packaging industry each and every day.",
            "image": "/corporate_logos/mcgahee.png",
            "website": "https://mcgahee.com/"
        },

        {
            "title": "Miller Milling Co.",
            "description": "Flours to your most exacting specifications. State-of-the-art facilities across the U.S. and an intense interest in understanding your business and your goals. The only question is, when can we start?",
            "image": "/corporate_logos/miller.png",
            "website": "https://millermilling.com/"
        },

        {
            "title": "Mother Murphy's Laboratories, Inc.",
            "description": "Mother Murphy’s is a food flavoring business, family-owned and operated since 1946. We ship food flavorings, flavor extracts and powered flavorings to over 30 different countries. We are very innovative, and our in-house chemists are always developing and seeking new flavor extracts and powdered flavorings to add to our library of already more than 60,000 flavors.",
            "image": "/corporate_logos/motherMurphys.png",
            "website": "https://www.mothermurphys.com/"
        },

        {
            "title": "PPC Flexible Packaging",
            "description": "Our mission is to provide industry-leading service, quality, and value through creative flexible packaging solutions. By offering unique capabilities and unmatched speed to market, we transform the packaging experience for our customers.",
            "image": "/corporate_logos/ppc.jpg",
            "website": "https://ppcflex.com/"
        },

       

        {
            "title": "Shick Esteve",
            "description": "Quality, robust ingredient automation. Reliable, repeatable process management. Unmatched service and exceptional value that create enduring relationships. Welcome to Shick Esteve. The only global provider focused solely on food.",
            "image": "/corporate_logos/shick.png",
            "website": "https://shickesteve.com/"
        },

        {
            "title": "Sosland Publishing Company",
            "description": "Whether you’re looking to get your message in front of growing startups or established decision makers, the Food Entrepreneur Experience lets your brand reach an audience hungry for cutting-edge ingredients, equipment, and more.",
            "image": "/corporate_logos/sosland.png",
            "website": "https://www.sosland.com/"
        },

        {
            "title": "SPF Plastic",
            "description": "The SPF Groups formula is simple. We believe that dedicated and talented people, efficient process controls, and a clearly defined purpose create measurable customer value.",
            "image": "/corporate_logos/spf.png",
            "website": "https://spfgroups.com/"
        },

        {
            "title": "St. John's Packaging",
            "description": "Founded in 1954 by René J. Leclair, St. Johns Packaging is a vertically integrated manufacturer of flexible packaging products that are sold to leading food and consumer product companies. Still controlled by the Leclair family, the company currently operates wholly-owned subsidiaries in Canada, the United States, Mexico, the United Kingdom, China, Vietnam and Australia, employing more than 950 dedicated employees.",
            "image": "/corporate_logos/stjohns.svg",
            "website": "https://www.sjpack.com/"
        }



    ]

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={4} p={2} alignItems="stretch" >
                {members.map(d => (
                    <>
                        <Grid item xs={12} sm={12} md={6} lg={4} key={`grid${d['title']}`} >
                            <a href={`${d['website']}`} target="_blank" >
                                <CorporateCard image={d['image']} title={d['title']} description={d['description']} key={d['image']} />
                            </a>
                        </Grid>
                    </>
                ))}
            </Grid>
        </DashboardLayout>
    )
}
